import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArmazenamentoDeBaseService {
  private readonly chave = 'key_base';

  public armazenarBase(base: any) {
    return sessionStorage.setItem(this.chave, JSON.stringify(base));
  }

  public removerBase() {
    return sessionStorage.removeItem(this.chave);
  }

  public buscarBase(): any | null {
    const base = sessionStorage.getItem(this.chave);
    if (base) return JSON.parse(base);
    return null;
  }
}