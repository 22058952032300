import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArmazenamentoDeTokenService {
  public readonly chave = 'token_access';

  public armazenarToken(token: string) {
    return sessionStorage.setItem(this.chave, token);
  }

  public buscarToken() {
    return sessionStorage.getItem(this.chave);
  }

  public removerToken() {
    return sessionStorage.removeItem(this.chave);
  }
}